<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="pDialog"
        transition="dialog-bottom-transition"
        max-width="600"
      >
        <template >
          <v-card v-if="type == 'error'">
            <v-toolbar color="error" dark>
              <v-icon>mdi-alert-circle-outline</v-icon>
              {{ message }}
              <v-spacer />
              <v-btn @click="onClickOK">OK</v-btn>
            </v-toolbar>
          </v-card>
          <v-card v-if="type == 'OK'">
            <v-toolbar color="green" dark>
              <v-icon>mdi-check-circle-outline</v-icon>
              {{ message }}
              <v-spacer />
              <v-btn @click="onClickOK">OK</v-btn>
            </v-toolbar>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    pDialog: false,
    message: null,
    type: null
  },
  methods: {
    onClickOK(){
      this.$emit("acknowledgedEvent");
    }
  },
  updated() {
    //this.alertD = this.alert;
  },
  data() {
    return {
      //alertD: this.alert,
    };
  } /*  */,
};
</script>
