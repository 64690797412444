import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PwdResetView from "../views/PwdResetView.vue"

Vue.use(VueRouter)

import store from "../store";

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../components/Login.vue')
  },
  {
    path: '/',
    name: 'home',
    
    component: HomeView
  },
  {
    path: '/Account/reset/:id/:token',
    name: 'reset',
    component: PwdResetView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  ,
  {
    path: '/inventory',
    name: 'Inventory',
    meta: { role:['Admin','User']},
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InventoryView.vue'),
    props: true
    
  },
  ,
  {
    path: '/assets',
    name: 'Assets',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AssetsView.vue'),
    props: true
    
  },
  {
    path: '/reports',
    name: 'reports',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportsView.vue'),
    props: true
  },
  {
    path: '/settings',
    name: 'settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SettingsView.vue'),
    props: { areUsrsModified: store.getters.areUsrsModified}
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
/* router.beforeResolve((to,from,next) => {
  if(store.state.auth != null){
    
  }
  console.log("beforeResolveBegin");
  console.log(store.state.auth);
  console.log(to);
  console.log(from);
  //console.log(next);
}); */
router.beforeEach((to,from,next) => {
    console.log("Router -> beforeEach");

    /* if(store.getters.isAuthTokenExpired() == "YES"){
      if(!store.state.loading){
        store.dispatch("refresh", store.state.auth);
      }
      //console.log(store);
      
    } */
    /* else if(store.getters.isAuthTokenExpired() == "NO"){
      
    } */
    next();
  //else if(to.matched.some(route => route.meta.role))
  
  
  /* console.log(store.state.auth);
  console.log(store.state.auth.refreshToken); */
});

export default router
