<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card elevated dark>
        <v-form ref="lgnFrm" v-model="isFormValid" @submit.prevent="submitForm">
        <v-card-title class="grey darken-1"> Login <v-btn class="ml-auto" outlined color="yellow" @click="clearFields">Switch User</v-btn></v-card-title>
        <!-- <v-card-actions class="pa-5">
          
          <v-btn class="ml-auto" outlined color="yellow" @click="clearFields">Switch User</v-btn>
        </v-card-actions> -->
        <v-card-text class="pa-5">
            <v-text-field
              v-model="user"
              label="User"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="pass"
              label="Password"
              type="password"
              :rules="[rules.required]"
            ></v-text-field>
        </v-card-text>
        <v-card-actions class="pa-5">
          <v-btn class="ml-auto" outlined color="yellow" type="submit">Submit</v-btn>
        </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <msg-dlg
      :pDialog="loginErrorFlag"
      :message="loginErrorMsg"
      :type="loginErrorDlgType"
      @acknowledgedEvent="errorAckEvent"
    />
  </v-row>
</template>
<script>
import MsgDlg from "@/components/CommonComponents/MsgDlg.vue";
export default {
  props: {
    dialog: true,
  },
  components:{
    MsgDlg
  },
  data: () => ({
    loginErrorFlag: false,
    loginErrorMsg: null,
    loginErrorDlgType: "error",
    user: null,
    pass: null,
    isFormValid: false,
    userRules: [(value) => { if(value) return true; return "User is required";}],
    passRules: [(value) => { if(value) return true; return "Password is required";}],
    rules: {
      required: (value) => (!!value || value != null) || "This field is required",
      email: (v) => /.+@.+\..+/.test(v) || "Must be a valid email",
    },
  }),
  methods: {
    clearFields(){
      this.user = null;
      this.pass = null;
    },
    submitForm() {
      
      this.$refs.lgnFrm.validate();
      if(this.isFormValid){
        //this.login
        this.submitLogin();

      }
      
    },
    parseJwt (token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    },
    setIsUserAuthFlag(){
      this.$emit("setIsUserAuthFlag");
    },
    errorAckEvent(){
      this.loginErrorDlgType = null;
      this.loginErrorMsg = null;
      this.loginErrorFlag = false;
    },
    submitLogin(){
      const payload = {
        username: this.user,
        password: this.pass 
      };
      this.$store
        .dispatch("login", payload)
        .then((response) =>{
          
          var at = this.parseJwt(response.data.accessToken);
          this.setIsUserAuthFlag();
          this.error = null;
          this.user = "";
          this.pass = ""
          //this.router.push("/")
        })
        .catch((error) =>{
          /* this.loginErrorDlgType = "error";
          this.loginErrorMsg = 
          this.loginErrorFlag = true; */
          if(error){
            if(error.data){
              if(error.data.title == "Unauthorized"){
                this.loginErrorDlgType = "error";
                this.loginErrorMsg = error.data.title + "- Please check your credentials, and try again."
                this.loginErrorFlag = true;
              }
            }
          }
          console.log(error);
        });
    }
    
    //console.log(obj);
  },
};
</script>



