<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer">
      <v-list dense nav>
        <template v-for="navItem in navItems">
          <template v-if="navItem.navItems">
            <v-list-group
              :key="navItem.title"
              v-model="navItem.active"
              :prepend-icon="navItem.icon"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="navItem.title"></v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="child in navItem.navItems">
                <template v-if="child.navItems">
                  <v-list-group
                    :key="child.title"
                    v-model="child.active"
                    sub-group
                    prepend-icon=""
                  >
                    <template v-slot:activator>
                      <v-list-item-icon class="ml-2 mr-8">
                        <v-icon>{{ child.icon }}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="child.title"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <template v-slot:appendIcon>
                      <v-icon>mdi-chevron-down</v-icon>
                    </template>

                    <v-list-item
                      v-for="grandChild in child.navItems"
                      :key="grandChild.title"
                      link
                      :to="grandChild.link"
                      class="pl-14"
                    >
                      <v-list-item-icon class="mr-8">
                        <v-icon>{{ grandChild.icon }}</v-icon>
                      </v-list-item-icon>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{ grandChild.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-group>
                </template>
                <template v-else>
                  <v-list-item
                    :key="child.title"
                    link
                    :to="child.link"
                    class="pl-8"
                  >
                    <v-list-item-icon>
                      <v-icon>{{ child.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>{{ child.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </template>
            </v-list-group>
          </template>

          <template v-else-if="isUserInRoleFromState(navItem.roles)">
            <v-list-item :key="navItem.title" link :to="navItem.link">
              <v-list-item-icon>
                <v-icon>{{ navItem.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ navItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app extension-height="110px">
      <v-app-bar-nav-icon @click="appBarNavIconClick"></v-app-bar-nav-icon>
      <img
        src="./assets/Updated_Operations.png"
        height="90"
        width="90"
        class="showCursor"
      />
      <v-toolbar-title>{{ this.currentRoute }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="logOut" style="margin-right: 12px">
        <v-icon>mdi-logout</v-icon>
      </v-btn>

      <template v-slot:extension v-if="this.currentRoute == 'Inventory'">
        <v-container>
          <!-- <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="grey darken-1" dark
                >{{ selInvViewMenuItem }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in currentInvViewMenuItems"
                :key="item.name"
                link
                @click="setInvViewSelMenu(item.name)"
              >
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
          <v-row>
            <v-col sm="3" xs="3" md="3" lg="2">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="invReadingDate"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="invReadingDate"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!-- <v-col>
              <v-btn icon v-if="selInvViewMenuItem == 'Readings'">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </v-col> -->
          </v-row>

          <!-- <v-btn
            icon
            @click="invReadingDateDlg = !invReadingDateDlg"
            v-if="selInvViewMenuItem == 'Readings'"
          >
            <v-icon>mdi-calendar-edit</v-icon>
          </v-btn>
          <v-btn icon v-if="selInvViewMenuItem == 'Readings'">
            <v-icon>mdi-content-save</v-icon>
          </v-btn> -->

          <v-divider />
          <!-- <v-dialog
            ref="dialog"
            v-model="invReadingDateDlg"
            :return-value.sync="invReadingDate"
            persistent
            width="290px"
          >
            <v-date-picker v-model="invReadingDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="invReadingDateDlg = false">
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(invReadingDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog> -->

          <v-row>
            <v-col lg="2" sm="3">
              <v-btn-toggle
                v-model="toggle_exclusive"
                dense
                rounded
                class="bkcol"
                outlined
              >
                <v-btn small>
                  <v-icon :color="toggle_exclusive == 0 ? 'red' : 'grey'"
                    >mdi-oil</v-icon
                  >
                </v-btn>
                <v-btn small>
                  <v-icon :color="toggle_exclusive == 1 ? 'red' : 'grey'"
                    >mdi-storage-tank</v-icon
                  >
                </v-btn>
                <v-btn small>
                  <v-icon :color="toggle_exclusive == 2 ? 'red' : 'grey'"
                    >mdi-tanker-truck</v-icon
                  >
                </v-btn>
              </v-btn-toggle>
            </v-col>
            <v-col lg="2" sm="3">
              <v-autocomplete
                :value="selSrchItem"
                @change="srchItemSelected"
                :items="srchList"
                dense
                outlined
                clearable
                prepend-inner-icon="mdi-magnify"
                label="Search"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:extension v-else-if="this.currentRoute == 'settings'">
        <v-container>
          <!--  <v-card center-active dark>
            <v-tabs v-model="inventoryViewSelectedTab" align-with-title>
              <v-tabs-slider color="yellow"></v-tabs-slider>
              <v-tab v-for="item in inventoryViewTabItems" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
          </v-card> -->

          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="grey darken-1" dark
                >{{ selSetViewMenuItem }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in currentSetViewMenuItems"
                :key="item.name"
                link
                @click="setSetViewSelMenu(item.name)"
              >
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
            icon
            v-if="selSetViewMenuItem == 'User Settings'"
            @click="addUserDlgActFlag = true"
          >
            <v-icon>mdi-plus-circle</v-icon> </v-btn
          ><!--  -->
          <v-divider />
        </v-container>
      </template>
      <!-- -->
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <!-- If using vue-router -->
        <router-view
          :selectedMenuItem="selInvViewMenuItem"
          :invReadingDate="invReadingDate"
          @srchListFilledEvent="srchListFilledEvent"
          :selSrchItem="selSrchItem"
          :aTypeFilterVal="toggle_exclusive"
          ref="viw"
        >
        </router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
    <login
      :dialog="!this.isUserAuthenticatedFlag"
      @setIsUserAuthFlag="setIsUserAuthFlag"
    />
    <!-- <login :dialog="new Date(this.$store.getters.getExp) > new Date()" /> -->
    <add-user-dlg
      :pDialog="addUserDlgActFlag"
      @addDlgCancelEvent="addDlgCancelEvent"
      @addUsrsModEvent="addUsrsModEvent"
    />
  </v-app>
</template>

<script>
import axios from "axios";
import Login from "./components/Login.vue";
import AddUserDlg from "./components/SettingsViewComponents/AddUserDlg.vue";
export default {
  name: "Mainn",
  components: {
    Login,
    AddUserDlg,
  },
  created() {
    //this.refreshToken();
    //this.isUserAuthenticatedFlag = this.$store.getters.isAuthenticated();
    console.log("Main Created");
    this.setIsUserAuthFlag();
    this.currentRoute = this.$route.name; //this.$refs.rtr.$options.name;
    this.setElapedTime();
    this.invReadingDate = null;
    //this.setElapedTime();
  },
  watch: {
    toggle_exclusive(newVal, oldVal) {
      this.filterAData();
      /* console.log(oldVal);
      console.log(newVal); */
    },
  },
  updated() {
    console.log("Main Updated");
    //this.setElapedTime();
    //this.refreshToken();
    this.currentRoute = this.$route.name; //this.$refs.rtr.$options.name;
    //console.log(this.countDown);
    //this.setElapedTime();
  },
  data: () => ({
    timeoutId: null,
    toggle_exclusive: null,
    isUserAuthenticatedFlag: false,
    drawer: false,
    dateMenu: false,
    navItems: [
      {
        title: "Inventory",
        icon: "mdi-hydraulic-oil-level",
        link: "/inventory",
        roles: ["SuperAdmin", "Admin", "OfficePerson", "Driver"],
      },
      {
        title: "Assets",
        icon: "mdi-hand-coin",
        link: "/assets",
        roles: ["SuperAdmin", "Admin"],
      },

      {
        title: "Settings",
        icon: "mdi-cog",
        link: "/settings",
        roles: ["SuperAdmin"],
      },
    ],
    /* { title: "Reports", icon: "mdi-newspaper", link: "/reports" }, */
    loginDlgPopupFlag: false,
    countDown: 0,
    selSrchItem: null,
    srchList: [],
    fullSrchList: [],
    inventoryViewMenuItems: [{ name: "Tanks" }, { name: "Readings" }],
    settingsViewMenuItems: [{ name: "User Settings" }],
    selSetViewMenuItem: "User Settings",
    currentSetViewMenuItems: [{ name: "User Settings" }],
    currentInvViewMenuItems: [{ name: "Tanks" }],
    selInvViewMenuItem: "Readings",
    direction: "bottom",
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: false,
    left: false,
    transition: "slide-y-reverse-transition",
    inventoryViewTabItems: ["Tanks", "Readings"],
    inventoryViewSelectedTab: 1,
    currentRoute: null,
    search: null,

    addUserDlgActFlag: false,
    invReadingDateDlg: false,
    invReadingDate: null/*new Date(
      Date.now() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substring(0, 10),*/
  }),
  methods: {
    filterAData() {
      this.selSrchItem = null;
      var list = JSON.parse(JSON.stringify(this.fullSrchList));
      var tmpList = [];

      if (this.toggle_exclusive == 0) {
        for (var i = 0; i < list.length; i++) {
          if (list[i].type == "BP") tmpList.push(list[i].name);
        }
      } else if (this.toggle_exclusive == 1) {
        for (var i = 0; i < list.length; i++) {
          if (list[i].type == "LPY") tmpList.push(list[i].name);
        }
      } else if (this.toggle_exclusive == 2) {
        for (var i = 0; i < list.length; i++) {
          if (list[i].type == "Truck") tmpList.push(list[i].name);
        }
      } else {
        for (var i = 0; i < list.length; i++) {
          tmpList.push(list[i].name);
        }
      }
      this.srchList = tmpList;
    },
    setIsUserAuthFlag() {
      this.isUserAuthenticatedFlag = this.$store.getters.isAuthenticated();
      this.setElapedTime();
    },
    setElapedTime() {
      var cd =
        new Date(this.$store.getters.getExp * 1000).getTime() -
        new Date().getTime();
      console.log(cd);

      if (this.timeoutId == null) {
        if (cd > 0) {
          this.timeoutId = setTimeout(() => {
            console.log(new Date(this.$store.getters.getExp * 1000));
            console.log(new Date());
            this.isUserAuthenticatedFlag =
              this.$store.getters.isAuthenticated();
            this.setElapedTime();
          }, cd);
        } else {
          //this.refr();
          this.isUserAuthenticatedFlag = this.$store.getters.isAuthenticated();
        }
      } else {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
        this.setElapedTime();
      }
    },

    isUserInRoleFromState(roles) {
      /*if(this.$store.getters.isAuthTokenExpired() == 'YES'){
        this.$store.dispatch("refresh", this.$store.state.auth);
      } */
      return this.$store.getters.isUserInRole(roles);
    },

    appBarNavIconClick() {
      this.drawer = !this.drawer;
    },
    addDlgCancelEvent(value) {
      this.addUserDlgActFlag = value;
    },
    addUsrsModEvent() {
      //this.$router.push("/settings")
      //console.log(this.$refs.viw);
      this.$refs.viw.getUData();
    },
    srchListFilledEvent(list) {
      this.fullSrchList = JSON.parse(JSON.stringify(list));
      var tmpList = [];
      for (var i = 0; i < list.length; i++) {
        tmpList.push(list[i].name);
      }
      this.srchList = tmpList;
    },
    srchItemSelected(value) {
      console.log(value);
      this.selSrchItem = value;
      this.$refs.viw.filterBySrchItem(value);
    },
    setInvViewSelMenu(itmName) {
      this.selInvViewMenuItem = itmName;
      this.currentInvViewMenuItems = [];
      for (var i = 0; i < this.inventoryViewMenuItems.length; i++) {
        if (this.inventoryViewMenuItems[i].name != itmName) {
          this.currentInvViewMenuItems.push(this.inventoryViewMenuItems[i]);
        }
      }
    },
    setSetViewSelMenu(itmName) {
      this.selSetViewMenuItem = itmName;
      this.currentSetViewMenuItems = [];
      for (var i = 0; i < this.settingsViewMenuItems.length; i++) {
        if (this.settingsViewMenuItems[i].name != itmName) {
          this.currentSetViewMenuItems.push(this.settingsViewMenuItems[i]);
        }
      }
    },
    refr() {
      const payload = this.$store.state.auth;
      this.$store
        .dispatch("refresh", payload)
        .then((response) => {
          console.log("refr resp");
          console.log(response);
          this.isUserAuthenticatedFlag = this.$store.getters.isAuthenticated();
          this.setElapedTime();
          //var at = this.parseJwt(response.data.accessToken);
          /* this.setIsUserAuthFlag();
          this.error = null;
          this.user = "";
          this.pass = "" */
          //this.router.push("/")
        })
        .catch((error) => {
          console.log("refr error");
          console.log(error);
          this.isUserAuthenticatedFlag = this.$store.getters.isAuthenticated();
          /* this.loginErrorDlgType = "error";
          this.loginErrorMsg = 
          this.loginErrorFlag = true; */
          if (error) {
            /* if(error.data){
              if(error.data.title == "Unauthorized"){
                this.loginErrorDlgType = "error";
                this.loginErrorMsg = error.data.title + "- Please check your credentials, and try again."
                this.loginErrorFlag = true;
              }
            } */
          }
          console.log(error);
        });
    },
    refreshToken() {
      var exp = this.$store.getters.getExp;
      console.log(exp);
      if (exp) {
        console.log(new Date(exp * 1000));
      }
      //console.log(new Date(this.$store.getters.getExp()));
      if (this.$store.getters.isAuthTokenExpired() == "YES") {
        //if (!this.$store.state.loading) {
        const hdrs = {
          "Content-Type": "application/json",
          //'Access-Control-Allow-Origin': 'https://localhost:5000/#/'
        };

        axios
          .post("/api/token/refresh", this.$store.state.auth, { headers: hdrs })
          .then((response) => {
            const auth = response.data;
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${auth.accessToken}`;

            //context.commit("hideAuthModal");
            resolve(response);
            this.setIsUserAuthFlag();
          })
          .catch((error) => {
            this.setIsUserAuthFlag();
            delete axios.defaults.headers.common["Authorization"];
            //reject(error.response);
          });

        //}
        //console.log(store);
      }
    },
    logOut() {
      this.$store.dispatch("logout");
      this.isUserAuthenticatedFlag = this.$store.getters.isAuthenticated();
      this.$router.push("/");
    },
  },
};
</script>
<style scoped>
.bkcol {
  background-color: gray !important;
}
</style>