import axios from "axios";



export const login = (context, payload) => {
    return new Promise((resolve,reject) => {
        context.commit("loginRequest");
        
        const hdrs = {
            'Content-Type': 'application/json',
            //'Access-Control-Allow-Origin': 'https://localhost:5000/#/'
            
          }
        axios
            .post("/api/Authentication/login", payload, {headers:hdrs})
            .then((response) => {
                
                const auth = response.data;
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${auth.accessToken}`;
                context.commit("loginSuccess", auth);
                
                //context.commit("hideAuthModal");
                resolve(response);
                
            })
            .catch((error) => {
                
                context.commit("loginError");
                delete axios.defaults.headers.common["Authorization"];
                reject(error.response);
            });
    });
};
export const refresh = (context, payload) => {
    return new Promise((resolve,reject) => {
        context.commit("refreshRequest");
        
        const hdrs = {
            'Content-Type': 'application/json',
            //'Access-Control-Allow-Origin': 'https://localhost:5000/#/'
            
            
          }
          
        axios
            .post("/api/token/refresh", payload, {headers:hdrs})
            .then((response) => {
                
                const auth = response.data;
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${auth.accessToken}`;
                context.commit("refreshSuccess", auth);
                
                //context.commit("hideAuthModal");
                resolve(response);
            })
            .catch((error) => {
                
                context.commit("refreshError");
                delete axios.defaults.headers.common["Authorization"];
                //reject(error.response);
            });
    });
};
export const logout = (context, payload) =>
{
    context.commit("logout");
    delete axios.defaults.headers.common["Authorization"];
}

export const incrementRefreshCount = (context) =>
{
    context.commit("incrementRefreshCount");
}

