<template>
  <v-row justify="center">
    <v-dialog v-model="pDialog" persistent max-width="600px">
      <v-card elevated dark>
        <v-form
          ref="addUsrFrm"
          v-model="isFormValid"
          @submit.prevent="submitForm"
        >
          <v-card-title class="grey darken-2">
            Add User
            <v-spacer />
            <v-btn icon dark @click="cancelDlg">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-5">
            <v-container>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="firstname"
                    label="First Name"
                    :rules="[rules.required, rules.name]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="lastname"
                    label="Last Name"
                    :rules="[rules.required, rules.name]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="email"
                    label="Email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="phonenumber"
                    label="Phone"
                    :rules="[rules.required, rules.phone]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    type="password"
                    v-model="password"
                    label="Password"
                    :rules="[rules.required, rules.pass]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    type="password"
                    v-model="cpw"
                    label="Confirm Password"
                    :rules="[rules.required, cPassRule]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    :items="roleItems"
                    label="Role"
                    :rules="[rules.required]"
                    @change="onRoleSelect"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-5">
            <v-btn class="ml-auto" outlined color="yellow" type="submit"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from "axios";
export default {
  name: "AddUserDlg",
  props: {
    pDialog: true,
    /* firstName: null,
    lastName: null,
    email: null,
    pw: null,
    phone: null */
  },
  data: () => ({
    firstname: null,
    lastname: null,
    email: null,
    phonenumber: null,
    password: null,
    cpw: null,
    isFormValid: false,
    roleItems: ["Admin", "OfficePerson","Driver", "Disabled"],
    roles: [],
    rules: {
      required: (value) => !!value || value != null || "This field is required",
      name: (v) => /^[A-Za-z]{2,20}$/.test(v) || "Invalid name",
      email: (v) => /.+@qocnc.com/.test(v) || "Must be a valid email",
      pass: (v) =>
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
          v
        ) || "Must be a valid password",
      phone: (v) =>
        /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(v) ||
        "Invalid Phone",
    },
  }),

  methods: {
    cPassRule(v) {
      return (
        (/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
          v
        ) &&
          v == this.password) ||
        "Must be a valid password"
      );
    },
    onRoleSelect(ev) {
      this.roles = [];
      this.roles.push(ev);
    },
    cancelDlg() {
      this.$refs.addUsrFrm.reset();
      this.$emit("addDlgCancelEvent", false);
    },

    submitForm() {
      this.$refs.addUsrFrm.validate();
      if (this.isFormValid) {
        var payload = {
          firstname: this.firstname,
          lastname: this.lastname,
          username: this.firstname + this.lastname,
          email: this.email,
          phonenumber: this.phonenumber,
          password: this.cpw,
          roles: this.roles,
        };
        const hdrs = {
          "Content-Type": "application/json",
          //'Access-Control-Allow-Origin': 'https://localhost:5000/#/'
          'Authorization': `Bearer ${this.$store.state.auth.accessToken}`
        };
        axios
          .post("/api/authentication/users", payload, {
            headers: hdrs,
          })
          .then((response) => {
            
            this.$emit("addUsrsModEvent");
            
            //context.commit("hideAuthModal");
            //resolve(response);
          })
          .catch((error) => {
            console.log(error);
            //context.commit("loginError");
            //delete axios.defaults.headers.common["Authorization"];
            //reject(error.response);
          });

        
        //this.login
        //this.submitLogin();
        this.cancelDlg();
      }
    },

    //console.log(obj);
  },


};
</script>



