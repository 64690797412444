<template>
  <mainn />
</template>

<script>
import Mainn from './Mainn.vue';


export default {
  name: "App",
  components: { Mainn },
  
  computed:{
    showLoginModal(){
      return true;
    }
  }
  
};
</script>
<style scoped>
</style>