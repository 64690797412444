import jwtDecode from "jwt-decode";

export const isAuthenticated = (state) => () => {
    
    const token = state.auth ? state.auth.accessToken : null;
    
    const decoded = token == null ? null : jwtDecode(token);
    
    const expDt = decoded?.exp == null ? 0 : decoded?.exp;
    

    

    return(
        state.auth !== null &&
        state.auth.accessToken !== null &&
        new Date(expDt * 1000) > new Date() 
    );

}

export const isAuthTokenPresent = (state) =>{
    if(state.auth == null){
        return false;
    }
    else if(state.auth.accessToken == null){
        return false;
    }
    return true;
}

export const getUserRole = (state) => {
    
    if(state.auth == null){
        return null;
    }
    if(state.auth.accessToken == null){
        return null;
    }
    const token = state.auth.accessToken;
    const decoded = jwtDecode(token);
    if(decoded == null){
        return null;
    }
    const expDt = decoded?.exp == null ? 0 : decoded?.exp;
    var isTokExp = true;
    if(new Date(expDt * 1000) < new Date()){
        isTokExp = true;
    }
    else{
        isTokExp = false;
    }
    if(isTokExp == true){
        return null;
    }
    var tokKeys = Object.keys(decoded);
    var roleKey = null;
    for(var i = 0; i < tokKeys.length; i++){
        if(tokKeys[i].endsWith("role")){
            roleKey = tokKeys[i];
            break;
        }
    }
    if(roleKey == null){
        return null;
    }
    console.log(decoded[roleKey]);
    return decoded[roleKey];
}

export const isUserInRole = (state) => (roles) =>{
    var retVal = false;
    if(state.auth == null){
        return false;
    }
    if(state.auth.accessToken == null){
        return false;
    }
    const token = state.auth.accessToken
    const decoded = jwtDecode(token);
    if(decoded == null){
        return false;
    }
    const expDt = decoded?.exp == null ? 0 : decoded?.exp;
    var isTokExp = true;
    if(new Date(expDt * 1000) < new Date()){
        isTokExp = true;
    }
    else{
        isTokExp = false;
    }
    if(isTokExp == true){
        return false;
    }
    var tokKeys = Object.keys(decoded);
    var roleKey = null;
    for(var i = 0; i < tokKeys.length; i++){
        if(tokKeys[i].endsWith("role")){
            roleKey = tokKeys[i];
            break;
        }
    }
    if(roleKey == null){
        return false;
    }
    for(var i = 0; i < roles.length; i++){
        if(decoded[roleKey] == roles[i]){
            retVal = true;
            break;
        }
    }
    return retVal;
    /* if(state.auth == null){
        return "N/A";
    }
    if(state.auth.accessToken == null){
        return "N/A";
    }
    const token = state.auth.accessToken
    const decoded = jwtDecode(token);
    const expDt = decoded?.exp == null ? 0 : decoded?.exp;
    return(
        new Date(expDt * 1000) < new Date() ? "YES" : "NO"
    ); */
}
export const getExp = (state) => {
    if(state.auth == null){
        return 0;
    }
    if(state.auth.accessToken == null){
        return 0;
    }
    var token = state.auth.accessToken;
    var decoded = jwtDecode(token);
    return decoded?.exp == null ? 0 : decoded?.exp;
}
//Assumes that Access Token is present
export const isAuthTokenExpired = (state) => () => {
    if(state.auth == null){
        return "N/A";
    }
    if(state.auth.accessToken == null){
        return "N/A";
    }
    var token = state.auth.accessToken;
    var decoded = jwtDecode(token);
    var expDt = decoded?.exp == null ? 0 : decoded?.exp;
    //console.log(expDT);
    
    return(
        new Date(expDt * 1000) < new Date() ? "YES" : "NO"
    );
}

export const areUsrsModified = (state) =>{
    return state.areUsrsModified;
}