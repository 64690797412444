export const loginRequest = (state) =>{
    state.loading = true;
};
export const refreshRequest = (state) =>{
    state.loading = true;
};
export const loginSuccess = (state, payload) =>{
    console.log(payload);
    state.auth = payload;
    state.loading = false;
};

export const refreshSuccess = (state, payload) =>{
    
    state.auth = payload;
    state.loading = false;
};

export const loginError = (state) => {
    state.loading = false;
};

export const refreshError = (state) => {
    //state.auth = null;
    state.loading = false;
};


export const logout = (state) => {
    state.auth = null;

};

